/*1rem=10px*/
.modal-container {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(2px);
  background-color:$black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-container-otp {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(2px);
  background-color:$black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ez-modal{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-otp{
  @extend .ez-modal;
  top: 41%;
}
.modal-size{
  width: 70vw;
}
@media(min-width:"460px") {
  .modal-size{
    width: 55vw;
  } 
}

/*terms & conditions && Privacy Policy*/
.popup-1 {
  position: relative;
  height: 80vh;
  width: 90vw;
  background-color: $white;
  border-radius: 2.62715rem;
  img {
    position: absolute;
    right: 2.19rem;
    top: 2.19rem;
  }
}
.popup-1-content {
  height: 55vh;
  margin: 2.811rem 2.627rem 0 2.627rem;
  padding: 0 1rem 0 .6rem;
  div p{
    word-wrap: break-word;
  }
  ol{
    padding-left: 1.2rem;
  }
}

/*language & location*/
.popup-2 {
  .popup-2-inner {
    min-width: 30.6rem;
    height: 18.274rem;
    border-radius: 1.6rem;
    background-color: $dodgerBlue;
    position: relative;
  }
  h2 {
    padding: 4.874rem 0 0 2.3rem;
  }
  p {
    padding: 1.1rem 0 0 2.3rem;
    max-width: 22rem;
  }
  button {
    width: 11.291rem;
    height: 4.6rem;
    border-radius: 0.4rem;
    border: none;
    position: absolute;
    right: 1.009rem;
    bottom: 1.7rem;
  }
  img {
    position: absolute;
    right: 0.5rem;
    top: 1.574rem;
  }
}
.traingle {
  width: 0;
  height: 0;
  border-left: 1.3265rem solid transparent;
  border-right: 1.3265rem solid transparent;
  border-top: 2.653rem solid $dodgerBlue;
  position: absolute;
  right: 1.7rem;
  bottom: -1.4rem;
  transform: rotate(-9deg);
}
.traingle-2{
  width: 0;
  height: 0;
  border-top: 0.5px solid transparent;
	border-bottom: 13.5px solid transparent;
  position: absolute;
  right: -1px;
  bottom: -14px;
  z-index:-1;

}

// profile -change name
.popup-confirm-type-1 {
  width: 27.6rem;
  background: $white;
  border-radius: 2.4rem;
  h2 {
    padding-top: 0.8rem;
    padding-bottom: 1.6rem;
  }
  input {
    min-height: 1.9rem;
    padding: 1.3rem;
    border: 0.1rem solid $grandmaGray;
    border-radius: 0.5rem;
  }
  button:nth-of-type(1) {
    margin-right: 1.6rem;
  }
}
.error {
  @include flexbox(normal, center);
  span {
    @include alignment(2rem, -0.005em);
    @include font( $font-xs, $font-weight-regular);
    color: $maroon;
  }
}
//ineligble service
/* manage profile-attention*/
/* delete schemes/jobs/services/notifications , logout & review survey*/
.popup-confirm-type-5{
  @include flexbox(normal, center, column);
  background: $white;
  border-radius: 1.2rem;
}
// no jobs-popup
.popup-confirm-type-4 {
  @extend .popup-confirm-type-5;
  p{
    width: 90%;
  }
}

//delete profile,delete account,delete data & expired jobs popup
.popup-alert-type-1 {
  @extend .popup-confirm-type-5;
  border-radius: 1.2rem;
  position: relative;
  h4 {
    max-width: 26.4rem;
  }
  .close {
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
  }
}

.popup-doc-prev {
  @extend .popup-1 ;
    img {
      position: absolute;
      right: 0.5rem;
      top: -3.19rem;
    }
  }
  .popup-application-details-doc {
    position: relative;
    width: 90vw;
    background-color: $white;
    height: 55vh;
    border-radius:0;
  }
  .close-doc
  {
      position: absolute;
      right: 0.145rem;
      top: -3.19rem;
  }
    .popup-application-details-content
  {
    @extend .popup-1-content;
    margin: 0 !important;
    position: relative !important;
    height: 55vh !important;
    overflow:  overlay !important;
  }
  
  .prev-img-responsive{
    width: 100%;
    height: 100%;
  }
  
  .popup-img-prev {
    @extend .popup-1;
    img {
      right: 0;
      top: 0;
    }
    background-color: transparent;
  }

.popup-doc-prev-content {
  @extend .popup-1-content;
  margin: 0 !important;
  position: relative !important;
  top: 3rem !important;
  overflow:  overlay !important;
}

.popup-img-prev-content {
  @extend .popup-doc-prev-content;
  position: relative;
  top: 8rem;
  bottom: 8rem;
}

.attachment-modal{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-loader{
  @extend .ez-modal;
}

// Modal for payment Start
.modal-payment{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
}

.modal-size-payment{
  width: 87vw;
  background-image: url("../scssImgs/paymentModal/payment-popup-bg.svg") !important;
  background-repeat: no-repeat !important;
}

.text-center-payment {
    text-align: left;
    font-size: 3vh;
    line-height: 3.4rem;
}

.bttn-payment{
  position: fixed;
  bottom: 20px;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 90%
}

.popup-confirm-type-5-payment{
  @include flexbox(normal, center, column);
  background: $white;
  border-radius: 1.2rem;
}

.popup-alert-type-1-payment {
  @extend .popup-confirm-type-5-payment;
  border-radius: 1.2rem 1.2rem 0rem 0rem;
  position: fixed;
  bottom: 0;
  h4 {
    max-width: 26.4rem;
  }
  .close {
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
  }
}

.payment{
  color: $productColor;
  margin-top: 1.2rem;
  margin-bottom: 10rem;
  margin-right: auto;
  letter-spacing: 2px;
  font-size: 2.5em;
}
.payment-close{
  position: absolute;
  top: -4rem;
  right: 0.8rem;
  background-color: white;
  border-radius: 1.5rem;
}
// Modal for payment End
.cross-icon
{
  width: 10px;
  position: relative;
  left: 128px;
  bottom: 5px;
}
.ratings-container
{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1px;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 40px 0;
    box-shadow: 0 -1px #958f8f;
}
.ratings-experience-text
{
  font-size: 1.6rem;
  word-spacing: 2px;
  margin: 0 40px;
}
.single-star span
{
  padding: 22px 27px 6px 0;
}
.rating-text-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 21.4em;
}
.rating-text-container > * {
  flex: 1;
  font-size: 1.2rem;
}
.close-rating-popup
{
  position: absolute;
  right: 1.6rem;
  top: -3rem;
  width: 23px;
}
.popup-application-details-doc {
  position: relative;
  width: 90vw;
  background-color: $white;
  height: 55vh;
  border-radius:0;
}
.close-doc
{
    position: absolute;
    right: 0.145rem;
    top: -3.19rem;
}
  .popup-application-details-content
{
  @extend .popup-1-content;
  margin: 0 !important;
  position: relative !important;
  //top: 3rem !important;
  overflow:  overlay !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ratings-container
{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1px;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 40px 0;
    box-shadow: 0 -1px #958f8f;
}
.ratings-experience-text
{
  font-size: 1.6rem;
  word-spacing: 2px;
  margin: 0 40px;
}
.single-star span
{
  padding: 22px 27px 6px 0;
}

.rating-text-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 21.4em;
}

.rating-text-container > * {
  flex: 1;
  font-size: 1.2rem;
}
.close-rating-popup
{
  position: absolute;
  right: 1.6rem;
  top: -3rem;
  width: 23px;
}

// Modal for Payment app tray START
.modal-size-payment-app-tray{
  width: 87vw;
  
}
.popup-alert-type-1-payment-app-tray {
  overflow-x: scroll;
  align-items: flex-start !important;
  @extend .popup-confirm-type-5-payment;
  border-radius: 1.2rem 1.2rem 0rem 0rem;
  position: fixed;
  bottom: 0;
}
.modal-payment-app-tray{
  position: fixed;
  left: 50%;
  top: 60%;
  transform: translate(-50%, 0%);
}
.text-center-payment-app-tray {
  text-align: left;
  font-size: 3vh;
  line-height: 3.4rem;
  font-weight: 600;
}
.payment-app-tiles-grid{
  display: flex;
  justify-content: space-between;
  padding: 0px;
  gap: 35px;
  width: 313px;
  height: 55px;
  left: 31px;
  top: 88px;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 20px;
}

.payment-app-tiles-grid-img{
box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 12px 4px;
gap: 10px;
width: 55px;
height: 55px;
background:x#FFFFFF;
// border: 1px solid #DADADA;
// border-radius: 10px;
flex: none;
order: 0;
flex-grow: 0;
}

.payment-app-tray-name{
  display: flex;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 80px;
  justify-content: center;
  line-height: 13px;
  text-align: center;
  width: 41px;
}
.payment-app-images{
  height: 50px;
  width: 50px;
  padding-top: 20px;
}
// Modal for Payment app tray END
